<template>
    <section>
        <div class="row mx-0 border-bottom py-2 px-3">
            <div class="col-auto text-general f-17 f-600">
                Motivos de movimientos de productos
            </div>
        </div>
        <div class="row mx-0 border-bottom">
            <router-link 
            v-for="(ruta, m) in motivos" :key="m" 
            :to="{name: ruta.ruta}" 
            :class="`col py-2 tres-puntos text-center pt-3 ${ruta.ruta == $route.name ? 'active-a' : ''}`"
            >
                {{ ruta.titulo }}
            </router-link>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 218px);">
            <router-view />
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {
            motivos: [
                {
                    titulo: 'Retiro por cedis',
                    ruta: 'admin.conf.productos.retiros-cedis'
                },
                {
                    titulo: 'Traslados por cedis',
                    ruta: 'admin.conf.productos.traslados-cedis'
                },
                {
                    titulo: 'Retiros por Leecheros',
                    ruta: 'admin.conf.productos.retiros-leecheros'
                },
                {
                    titulo: 'Devolucion por leecheros',
                    ruta: 'admin.conf.productos.devolucion-leecheros'
                }
            ]
        }
    },
    mounted(){
        this.motivos[2].titulo = `Retiros por ${this.$config.vendedor}`
        this.motivos[3].titulo = `Devolucion por ${this.$config.vendedor}`
    }
}
</script>
<style lang="scss" scoped>
.active-a{
    text-decoration: none !important;
    color: var(--color-general) !important;
    border-bottom: 3px solid var(--color-general) !important;
    &:hover{
        text-decoration: none !important;
        color: var(--color-general) !important;
    }
}
a{
    text-decoration: none !important;
    color: var(--color-general2) !important;
}
</style>
